.sobre{
	padding-bottom: 14vw;
}

.sobre h2{
	color: var(--primaryTextColor);
}

.txt-sobre{
	color: var(--primaryTextColor);
	font-family: "Gotham Medium", Arial, sans-serif;
	font-size: 12px;
	line-height: 1.6;
	margin-top: 1rem;
	text-align: center;
}

@media only screen and (min-width: 960px){

	.txt-sobre{
		font-family: 'Gotham Book', Arial, sans-serif;
	}
}
