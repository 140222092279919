/* Theme Vars */
html, html[data-theme="credies"] {
	--primaryTextColor: #600075;
	--secondaryTextColor: #FE0472;
	--primaryBackground: #FFEC00;
	--secondaryBackground: #8AFF00;
	--urlOndaDesk: url('./assets/img/onda-desk.png');
	--urlOndaMob1: url('./assets/img/onda-mob.png');
	--comoFuncionaToggleDisabledBackgroundColor: var(--secondaryTextColor);
	--comoFuncionaToggleBackgroundColor: var(--secondaryTextColor);
	--comoFuncionaToggleTextColor: #FFFFFF;
	--comoFuncionaTextColor: var(--primaryTextColor);

	--depoimentoTitleColor: var(--primaryTextColor);
	--depoimentoBgColor: #95CA1B;
	--depoimentoPrimaryColor: #0C4A9B;
	--depoimentoSecondaryColor: #FFFFFF;
	--contrastTextColor: #FFFFFF;

	/* variaveis gerais */
	--btnDefaultBgColor: var(--secondaryTextColor);
	--btnDefaultTextColor: #FFFFFF;

	/* Cores landing-ie */
	--color-background:#F5F5F5;
	--color-background-form:#FFFFFF;
	--color-cinza:#C8C8C8;
	--color-blue:#78d0f1;
	--color-roxo:#8B349D;
	--color-yellow:#E1D601;
	--color-cinza-input:#666666;
}

/* Theme Transition */
html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}


body{
	font-family: 'Gotham Book', Arial, sans-serif;
	color: var(--primaryTextColor);
	background-color: #FAFAFA;
}

#root{
	overflow: hidden;
}

h1{
	margin-top: 4px;
}
h1, h2 {
	text-align: center;
}

h2 {
	font-family: 'Gotham Bold', Arial, sans-serif;
	font-size: 8vw;
	text-align: center;
	margin-top: 3rem;
	color: var(--secondaryTextColor);
}
h4 {
	font-family: 'Gotham Bold', Arial, sans-serif;
	font-size: 7vw;
	text-align: left;
	margin: 0px;
	color: var(--secondaryTextColor);
}
h5, p{
	margin-top: 0;
	color: var(--primaryTextColor);
}
.text-center{
	text-align: center;
}
.p-relative{
	position: relative;
}
img.fluid{
	width: 100%;
	height: auto;
	max-width:100%;
	margin-top: 15px;
}
.f-left{
	float: left;
}
.f-right{
	float: right;
}
.bg-primary{
	background: var(--primaryBackground);
}
.bg-secondary {
	background: var(--secondaryBackground);
}

.txt-primary{
	color: var(--primaryTextColor) !important;
}
.txt-secondary{
	color: var(--secondaryTextColor) !important;
}

.btn{
	text-transform: capitalize !important;
}
button.MuiButton-textPrimary,
a.MuiButton-textPrimary,
.btn.btn-default{
	background-color: var(--btnDefaultBgColor);
	border-radius: 25px;
	color: var(--btnDefaultTextColor);
	padding: 10px 40px;
	border: solid 2px var(--btnDefaultBgColor);
	font-family: 'Gotham Bold', Arial, sans-serif;
	width:75%;
}

.btn.btn-default.inverse{
	background-color:var(--btnDefaultTextColor);
	color: var(--btnDefaultBgColor);
	border: solid 2px var(--btnDefaultTextColor);
}

.btn-default:hover,
.btn-default:active,
.btn-entrar:hover,
.btn-entrar:active,
.btn-outline:hover,
.btn-outline:active
{
	background-color: #d50460 !important;
	border-color: #d50460 !important;
	color: #fff !important;
}

.btn.btn-outline{
	background-color:var(--btnDefaultTextColor);
	border-radius: 25px;
	color: var(--btnDefaultBgColor);
	padding: 10px 30px;
	margin-bottom: 10px;
	border: solid 2px var(--btnDefaultBgColor);
	font-family: 'Gotham Bold', Arial, sans-serif;
	width:75%;
}
.btn.btn-outline.inverse{
	background-color:var(--btnDefaultBgColor);
	color: var(--btnDefaultTextColor);
	border: solid 2px var(--btnDefaultTextColor);
}

.btn.btn-outline.btn-borderless{
	border: 2px solid #fff;
}

.bloco-modelo{
	position: absolute;
	background-color: var(--depoimentoBgColor);
	border-radius: 25px;
	text-align: center;
	padding: 8px 30px;
}
.bloco-modelo h5{
	color: var(--depoimentoPrimaryColor);
	font-family: "Gotham Bold", Arial, sans-serif;
	text-transform: uppercase;
	margin-bottom: 0;
	font-size: 4vw;
}

.bloco-modelo p{
	color: #FFF;
	margin-bottom: 0;
	font-size: 3vw;
}

.bloco-modelo p span{
	color: var(--depoimentoPrimaryColor);
	font-family: "Gotham Bold", Arial, sans-serif;
}

.close-button {
    float: right;
    padding: 0;
    color: #D1DCED;
}

.d-block{
	display: block;
}

.no-desk{
	display: block !important;
}
.no-mobile{
	display: none !important;
}
.select-curso .MuiPaper-root.MuiPaper-elevation1 DIV{
	max-height: 250px;
}
.MuiDialog-root .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}
.MuiContainer-root.MuiContainer-maxWidthLg{
	max-width: 600px;
}

.mainBody .MuiTabs-indicator {
    color: none;
    display: none;
}

@media only screen and (min-width: 600px){	
	h2{
		font-size: 36px;
	}
	h4{
		font-size: 30px;
	}
	.bloco-modelo h5 {
		font-size: 18px;
	}
	.bloco-modelo p {
		font-size: 16px;
	}
}
@media only screen and (min-width: 960px){	
	.MuiContainer-root.MuiContainer-maxWidthLg{
		max-width: 1280px;
	}
	.no-desk{
		display: none !important;
	}
	.no-mobile{
		display: block !important;
	}
	h2{
		font-size: 2.8em;
	}
	h4{
		font-size: 2.5vw;
	}
	.depoimentos .btn-default{
		float: right;
	}
	.depoimentos .btn-outline{
		float: left;
	}
	.depoimentos .group-btn{
		margin-top: 6vw;
	}
	.slick-dots li.slick-active button:before{
		opacity: 1;
		color: #0C4A9B;
		transform: scale(2);
	}

	html[data-theme="credneo"] .slick-dots li button:before{
		color: #FFFFFF !important;
	}
}
