.btn-group-social{
    position: fixed;
    left: 5%;
    bottom: 6rem;
    z-index: 999;
}

.btn-group-social .helper{
    background-color: #F0F5FC;
    color: #827f84;
    font-size: 16px;
    text-align: left;
    border-radius: 25px;
    display: inline-block;
    width: 150px;
    z-index: 2;
    line-height: 1;
    margin-left: -35px;
    padding: 10px 0 10px 50px;
    vertical-align: middle;
    box-shadow: 0 8px 16px -4px rgba(0,0,0,0.2);
}

@media(max-width: 600px){
    .btn-group-social .helper{
        display: none;
    }
}

.btn-group-social .hide-button{
    display:none;
}

.max-width-height{
    max-width: 30px;
    max-height: 30px;
    display: inline-block;
    margin-right: 10px;
}

.botoes-sociais a{
    text-decoration: none;
    color: #827f84;
    font-size: 15px;
    line-height: 30px;
}

.botoes-sociais .botao-social-zd{
    color: #827f84;
    font-size: 15px;
    line-height: 30px;
    text-transform: none;
    padding: 0;
}

.botoes-sociais .chat-icon{
    color: #0078ff;
}

.botoes-sociais .botao-social-zd:hover{
    color: #827f84;
    font-size: 15px;
    line-height: 30px;
    text-transform: none;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.00);
    text-decoration: none;
    cursor: pointer;
}

.title{
    color: #827f84;
    font-size:18px;
    line-height: 1;
    margin-bottom: 20px;
}

.botoes-sociais .border-bottom{
    border-bottom: #dedede;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.botoes-sociais div{
    padding: 10px 0px 10px 0px;
}

.btn-social{
    border-radius: 100% !important;
    background-color: var(--btnDefaultBgColor) !important;
    padding: 15px 15px !important;
    font-family: 'Gotham Bold', Arial, sans-serif !important;
    z-index: 2;
    display: inline-block;
}