.compare{
	padding: 0 0 12vw 0;
}

.compare h2{
	margin-top: 2rem;
}

.txt-compare{
	color: #67778F;
	font-family: "Gotham Medium", Arial, sans-serif;
	font-size: 13px;
}

.tabela-compare{
	box-shadow: 0 8px 16px -4px rgba(0,0,0,0.2);
	border-radius: 4px;
	margin-top: 3vw;
	margin-bottom: 6vw;
	font-size: 3.5vw;
}
.tabela-compare .MuiCardContent-root{
	padding: 0;
	display: flex;
}
.tabela-compare p{
	margin: 0 auto;
}

.tabela-compare .MuiCardContent-root .MuiGrid-item{
	height: 18vw;
	text-align: center;
	color: #0C4A9B;
	align-items: center;
	display: flex;
	border-left: 1px solid #dcdee0;
	border-bottom: 1px solid #dcdee0;
}
.tabela-compare .MuiCardContent-root .coluna-azul .MuiGrid-item{
	border-bottom: 0;
}

.tabela-compare .coluna-md{
	flex-grow: 0;
	max-width: 21.7%;
	flex-basis: 21.7%;
}
.tabela-compare .coluna-lg{
	flex-grow: 0;
	max-width: 34%;
	flex-basis: 34%;
}
.compare .btn{
	margin: 0 auto;
}
.tabela-compare .MuiGrid-container{
	border-bottom: 1px solid #dcdee0;
}

.tabela-compare .MuiCardContent-root:last-child{
	padding-bottom: 0;
}
.tabela-compare .MuiGrid-container div:first-of-type{
	border-left: 0;
}
.tabela-compare .coluna-azul{
	background-image: linear-gradient(to bottom, var(--secondaryTextColor), #CE005C);
}
.tabela-compare .coluna-azul #borda-superior {
	background-color: var(--secondaryTextColor);
	height: 4px;
	margin-top: -4px; 
	margin-left: 1px; 
	position: absolute;
	border: none;
	border-radius: 4px 4px 0px 0px;
}
.tabela-compare .coluna-azul #borda-inferior {
	background-color: #CE005C;
	height: 4px;
	margin-left: 1px; 
	position: absolute;
	border: none;
	border-radius: 0px 0px 4px 4px;
} 
.tabela-compare .coluna-azul p,
.tabela-compare .coluna-azul .icone-compare{
	color: #fff !important;
}
.coluna-cinza p{
	color: #67778F;
	font-size: 3vw;
	padding: 4px;
}
.tabela-compare .coluna-md .icone-compare{
	display:block;
	margin: 0 auto 4px;
	font-size: 4vw;	
	color: var(--primaryTextColor);
}

.tabela-compare .tit-compare{
	font-family: 'Gotham Bold', Arial, sans-serif;
	color: var(--primaryTextColor);
}

.text-center-mob{
	text-align: center;
}

.tabela-compare img{
	margin: 0 auto;
	width: 50%;
}

@media only screen and (min-width: 600px){
	.coluna-cinza p,
	.tabela-compare {
		font-size: 14px;
	}
	.tabela-compare .coluna-md .icone-compare {
		font-size: 20px;
	}
	.tabela-compare .MuiCardContent-root .MuiGrid-item {
		height: 100px;
	}
	.compare .btn{
		width: 90%;
	}
}	
@media only screen and (min-width: 960px){
	.tabela-compare{
		font-size: 1vw;
	}
	.tabela-compare .MuiCardContent-root .MuiGrid-item{
		height: 5.8vw;
	}
	.coluna-cinza p{
		font-size: 1vw;
	}
	.tabela-compare .coluna-md .icone-compare{
		font-size:1.6vw;
	}
	.compare{
		padding: 1vw 0 5vw 0;
	}
	.compare h2{
		padding: 0 30px;
	}
	.tabela-compare .coluna-lg p{
		font-family: 'Gotham Bold', Arial, sans-serif;
	}
	.compare button.btn{
		width: 100%;
		max-width: 250px;
	}
	.compare .btn-default{
		float:right;
	}
	.compare .btn-outline{
		float:left;
	}
	.tabela-compare img{
		width: 80%;
	}
}
