.menu-mobile{
	background-color: var(--secondaryTextColor);
	color: #fff;
	position: fixed;
	text-align: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100000000;
}

.menu-mobile a{
	color: #fff;
	display: block;
	text-align: center;
	text-decoration: none;
	padding: 15px 0;
	font-family: 'Gotham Bold';
}

.menu-mobile .btn-close{
	color: #fff;
	float: left;
	font-size: 4vw;
	margin-top: 5px;
	margin-left: -3vw;
}

.menu-mobile h3{
	float: left;
	margin-top: 3.6vw;
	font-size: 2vw;
	font-family: 'Gotham Book', Arial, sans-serif;
	margin-left: -4vw;
	text-transform: uppercase;
}

.menu-mobile nav{
	display: block;
	margin-top: 50%;
}

.menu-mobile .btn.btn-outline{
	width: 90%;
	position: absolute;
	bottom: 15px;
	left: 5%;
	right: 5%;
}
