.politica-privacidade{
    padding: 18vw 0 12vw 0;
}

#politica-privacidade a{
    color: #0064E6;
    text-decoration: underline;
    cursor: pointer;

}

.politica-privacidade .txt-politica{
    color: #67778F;
    text-align: left;
    margin: 30px 0px 30px 0px;
    font-weight: 600;
}

.politica-privacidade .txt-politica-privacidade{
    color: #67778F;
    text-align: justify;
    margin-bottom: 10px;
}

.tabela-privacidade{
    box-shadow: 0 8px 16px -4px rgba(0,0,0,0.2);
    border-radius: 4px;
    margin-top: 3vw;
    margin-bottom: 6vw;
    font-size: 3.5vw;
}
.tabela-privacidade .MuiCardContent-root{
    padding: 0;
    display: flex;
}
.tabela-privacidade p{
    margin: 0px 20px 0px 20px;
    color: #67778F;
}

.tabela-privacidade button{
    position:absolute;
    right: 25px;
    background-color: Transparent;
    outline:none;
    border: none;
    color: #67778F;
}

.tabela-privacidade .MuiCardContent-root .MuiGrid-item{
    height: 18vw;
    text-align: left;
    color: #67778F;
    align-items: center;
    display: flex;
    border-left: 1px solid #dcdee0;
    border-bottom: 1px solid #dcdee0;
}

.tabela-privacidade .coluna-md{
    flex-grow: 0;
    max-width: 21.7%;
    flex-basis: 21.7%;
}
.tabela-privacidade .coluna-lg{
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
}
.politica-privacidade .btn{
    margin: 0 auto;
}
.tabela-privacidade .MuiGrid-container{
    border-bottom: 1px solid #dcdee0;
}

.tabela-privacidade .MuiCardContent-root:last-child{
    padding-bottom: 0;
}
.tabela-privacidade .MuiGrid-container div:first-of-type{
    border-left: 0;
}

.tabela-privacidade .coluna-md .icone-politica-privacidade{
    display:block;
    margin: 0 auto 4px;
    font-size: 4vw;
    color: #0C4A9B;
}

.tabela-privacidade .tit-politica-privacidade{
    font-family: 'Gotham Bold', Arial, sans-serif;
    color: #0C4A9B;
}
.tabela-privacidade img{
    margin: 0 auto;
}

.tabela-cookies{
    border-top: 1px solid rgba(224, 224, 224, 1);
}
.tabela-cookies .MuiTableRow-root .row-azul{
    background-color:aliceblue;
}

.tabela-cookies .MuiTableCell-root .nome{
    font-weight: 600;
}

.tabela-cookies .MuiTableHead-root .texto-label{
    font-size: 18px;
    font-weight: 600;
    margin: 0px 0px 0px 20px;
}

.tabela-cookies .MuiTableCell-root .descricao{
    margin-bottom: 20px;
    text-align: justify;
}

.tabela-privacidade .MuiExpansionPanelDetails-root{
    display:block;
}

.tabela-privacidade .MuiExpansionPanelDetails-root p,
.tabela-cookies .MuiExpansionPanelDetails-root p{
    margin: 0px 30px 20px 20px;
    text-align: justify;
}


.tabela-cookies .MuiTableRow-root .border{
    border-right: 1px solid rgba(224, 224, 224, 1);
}



@media only screen and (min-width: 600px){
    .tabela-privacidade {
        font-size: 14px;
    }
    .tabela-privacidade .coluna-md .icone-politica-privacidade {
        font-size: 20px;
    }
    .tabela-privacidade .MuiCardContent-root .MuiGrid-item {
        height: 100px;
    }
    .politica-privacidade .btn{
        width: 90%;
    }
}
@media only screen and (min-width: 960px){
    .tabela-privacidade{
        font-size: 14px;
    }
    .tabela-privacidade .MuiCardContent-root .MuiGrid-item{
        height: 4.5vw;
        position: relative;
    }
    .politica-privacidade{
        padding: 5vw 0 0 0;
    }
    .politica-privacidade h2{
        text-align: left;
        margin: 0px 30px 0px 0px;
        max-width: 100%;
        display: inline-block;
    }
    .tabela-privacidade .coluna-lg .label{
        font-family: 'Gotham Bold', Arial, sans-serif;
        text-align: left;
    }
    .politica-privacidade .btn-default{
        float:right;
    }
    .politica-privacidade .btn-outline{
        float:left;
    }
}
