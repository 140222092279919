.como-funciona{
	margin-top: -8vw;
}
.como-funciona .MuiContainer-root{
	position: relative;
	overflow: hidden;
	padding-bottom: 20vw;
}
.como-funciona h2{
	margin-top: 6rem;
}

.modelo-como-funciona{
	position: absolute;
	bottom: 0;
	right: -16%;
	height: 115vw;
}

html[data-theme="credneo"] .modelo-como-funciona{
	right: -10%;
}

.modelo-como-funciona-mod{
	position: absolute;
	bottom: 0;
	left: -16%;
	height: 115vw;
}

html[data-theme="credneo"] .modelo-como-funciona-mod{
	left: -20%;
}

.como-funciona p.txt-credito{
	margin-bottom: 0;
	font-family: 'Gotham Bold', Arial, sans-serif;
	font-size: 2.5vw;
	position: absolute;
	bottom: 42px;

}
.como-funciona .card-como-funciona{
	background-color: unset;
	border: 4px solid var(--comoFuncionaTextColor);
	box-shadow: none;
	padding: 0;
	margin-top: 2rem;
}

.como-funciona .card-como-funciona div{
	padding: 10px 15px;
}

.como-funciona .card-como-funciona p{
	font-family: "Gotham Bold", Arial, sans-serif;
	font-size: 3.2vw;
	margin-bottom: 0;
}

.btn-group-como-funciona{
	margin-top: 2rem;
}

.btn-group-como-funciona .btn {
	font-family: "Gotham Bold", Arial, sans-serif;
    border-radius: 20px;
    font-size: 3.2vw;
    text-transform: none;
    line-height: 30px;
    font-weight: 600;
    background-color: var(--comoFuncionaToggleDisabledBackgroundColor);
    color: var(--comoFuncionaToggleTextColor);
	border:none;
	opacity: 0.2;
}

.btn-group-como-funciona .btn.active {
	opacity: 1;
	background-color: var(--comoFuncionaToggleBackgroundColor);
    color: var(--comoFuncionaToggleTextColor);
}
.txt-como-funciona{
	margin-top: 2rem;
}

.txt-como-funciona p{
	color: var(--comoFuncionaTextColor);
	font-family: "Gotham Bold", Arial, sans-serif;
	font-size: 3.2vw;
	margin-bottom: 0;
}

.txt-como-funciona img{
	width: 80%;
	height: auto;
}

.txt-como-funciona.MuiGrid-container .MuiGrid-container {
	padding: 10px 0;
}

.txt-como-funciona.MuiGrid-container .MuiGrid-container .MuiGrid-item {
	display: flex;
	align-items: center;
	padding:0;
}
.txt-como-funciona.MuiGrid-container .MuiGrid-container .MuiGrid-item.MuiGrid-grid-xs-3 {
	justify-content: center;
}
.txt-como-funciona.MuiGrid-container .MuiGrid-container .MuiGrid-item.MuiGrid-grid-xs-9 {
	padding-left: 6px;
}

.como-funciona .bloco-modelo{
	bottom: -25px;
	right: 0px;
	border-radius: 25px 0 0 25px;
	padding-right: 15px;
	z-index: 10;
}

@media only screen and (min-width: 600px){
	.btn-group-como-funciona .btn{
		font-size: 16px;
	}
	.como-funciona{
		font-size: 14px;
	}
	.txt-como-funciona p,
	.como-funciona .card-como-funciona p{
		font-size: 18px;
	}
	.como-funciona p.txt-credito{
		font-size: 14px;
	}
	.modelo-como-funciona {
		bottom: 0;
		right: 0;
		height: 570px;
	}
	.modelo-como-funciona-mod{
		bottom: 0;
		left: 0;
		height: 570px;
	}
}
@media only screen and (min-width: 960px){	
	.como-funciona {
		margin-top: -1vw;
	}

	.txt-como-funciona {
		margin-bottom: 120px;
	}
	.como-funciona h2{
		margin-top: 260px;
		margin-bottom: 76px;
		font-size: 44px;
	}
	.como-funciona .card-como-funciona p{
		font-size: 16px;
		line-height: 26px;
		letter-spacing: -0.48px;
	}
	.modelo-como-funciona{
		height: 780px;
		right: -90px !important;
		bottom: -5.3vw;
	}
	.modelo-como-funciona.crednex{
		height: 780px;
		right: -104px !important;
		bottom: -5.3vw;
	}
	.como-funciona .txt-como-funciona p.txt-credito{
		font-size: 14px;
		line-height: 21px;
		bottom: 140px;
	}
	.como-funciona .MuiContainer-root{
		padding-bottom: 5vw;
	}
	.txt-como-funciona img{
		width: 60px;
		height: auto;
	}
	.como-funciona .card-como-funciona div {
		padding: 24px;
	}
	.como-funciona .card-como-funciona {
		border-width: 6px;
	}
	.como-funciona .card-como-funciona h4{
		margin-bottom: 15px;
		font-size: 38px;
		line-height: 42px;
	}
	.como-funciona .img-comp{
		width: 40%;
		margin-bottom: 0;
	}
	.como-funciona .txt-como-funciona p{
		margin-bottom: 0;
		font-size: 18px;
		line-height: 24px;
	}
	.como-funciona .bloco-modelo p{
		font-size: 14px;
		line-height: 16px;
	}
	.como-funciona .bloco-modelo h5{
		font-size: 20px;
		line-height: 20px;
	}
	.como-funciona .bloco-modelo{
		border-radius: 25px;
		padding: 4px 30px;
		margin: 0 auto;
		left: 0px;
		right: 0;
		width: 25%;
	}
	.txt-como-funciona.MuiGrid-container .MuiGrid-container {
		padding: 16px 0;
	}
	.txt-como-funciona.MuiGrid-container .MuiGrid-container .MuiGrid-item.MuiGrid-grid-xs-9 {
		padding-left: 0;
	}
}
