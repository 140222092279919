.modal-resultado-simulacao-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.modal-resultado-simulacao-root .close-button {
    margin-right: unset;
}

.modal-resultado-simulacao-root .logo-ies img {
    margin-top: 0px;
    margin-bottom: 30px;
    width: 50%;
}

.simulacao-resumo {
    padding-bottom: 124px;
    color: var(--primaryTextColor);
    font-family: 'Gotham Medium', Arial, sans-serif;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.resumo-simulacao-text {
    border: 3.58px solid var(--secondaryTextColor);
    padding-top: 12px;
    padding-bottom: 16px;
    margin-bottom: 20px;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
}

.resumo-simulacao-text .bold {
    font-family: 'Gotham Black', Arial, sans-serif;
}

.resumo-simulacao-text .simulacao-valor {
    letter-spacing: -1px;
    line-height: 57px;
    font-size: 52px;
    font-family: 'Gotham Ultra', Arial, sans-serif !important;
}

.simulacao-valor .prefixo {
    font-size: 22px;
    display: inline-block;
    vertical-align: super;
}

.simulacao-valor .centavos {
    font-size: 22px;
}

.simulacao-resumo button.btn {
    width: 260px;
    padding-left: 0px;
    padding-right: 0px;
    height: 47px;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
}

.simulacao-resumo button.btn.MuiButton-root{
    text-transform: initial !important;
}

.lk-simulacao-completa {
    font-size: 16px;
    font-family: 'Gotham Bold', Arial, sans-serif;
    margin-top: 24px;
    display: block;
    text-decoration: none;
    color: var(--primaryTextColor);
}

.lk-simulacao-completa svg {
    color: var(--secondaryTextColor);
}

.simulacao-completa {
    color: var(--primaryTextColor);
    font-family: 'Gotham Black', Arial, sans-serif;
    background-color: var(--primaryBackground);
    padding-top: 145px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    padding-bottom: 190px;
}

.simulacao-completa .titulo {
    font-size: 24px;
    line-height: 24px;
    display: block;
}

.simulacao-completa .subtitulo {
    font-size: 16px;
    line-height: 18px;
    display: block;
    margin-top: 30px;
}

.simulacao-completa ul {
    font-family: 'Gotham Book', Arial, sans-serif;
    font-size: 14px;
    line-height: 21px;
    padding-left: 15px;
}

.simulacao-completa .texto {
    font-family: 'Gotham Book', Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    padding-top: 15px;
    display: block;
}

.simulacao-completa ul a {
    font-family: 'Gotham Black', Arial, sans-serif;
    text-decoration: none;
}

.simulacao-completa .renda-minima {
    font-family: 'Gotham Book Italic', Arial, sans-serif;
    font-size: 14px;
}

.simulacao-completa .simulacao-completa-parcelas {
    margin-top: 20px;
    background-color: #FFF;
    padding: 15px 20px;
}

.simulacao-completa .calculadora {
    font-family: 'Gotham Book', Arial, sans-serif;
    font-size: 15px;
    border-bottom: 2px solid #E9E9E9;
    padding-bottom: 15px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}

.simulacao-completa .calculadora input {
    border: none;
    border-bottom: 2px dashed rgba(74,74,74,0.7);
    font-family: 'Gotham Black', Arial, sans-serif;
    text-align: center;
    color: var(--primaryTextColor);
    padding-bottom: 9px;
    font-size: 16px;
    width: 50%;
}

.simulacao-completa .calculadora a {
    font-size: 13px;
    padding: 3px 0;
}

.simulacao-completa .calculadora button {
    width: 100%;
    margin-top: 15px;
}

.simulacao-completa .semestres a {
    cursor: pointer;
}

.simulacao-completa-parcelas .parcelas {
    font-family: 'Gotham Bold', Arial, sans-serif;
    font-size: 18px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 15px;
}

.simulacao-completa-parcelas .valores .titulo {
    font-family: 'Gotham Bold', Arial, sans-serif;
    font-size: 18px;
    text-align: center;
}

.simulacao-completa-parcelas .valores {
    padding-bottom: 20px;
}

.simulacao-completa-parcelas .valores .valor-final {
    font-size: 20px;
    color: var(--secondaryTextColor);
    font-family: 'Gotham Bold', Arial, sans-serif;
}


.simulacao-completa-parcelas .valores .bold {
    font-family: 'Gotham Bold', Arial, sans-serif;
}

.simulacao-completa-parcelas button {
    width: 100%;
    padding: 10px 10px !important;
}
.btn-fullWidth button.btn{
    width: 100% !important;
}
.parcelas .MuiExpansionPanelSummary-root {
    background-color: #f9f9f9;
}

.parcelas .semestre-header {
    font-weight: 700;
    letter-spacing: -1px;
    font-family: 'Gotham Bold', Arial, sans-serif;
}

.parcelas .semestre-header .fa-arrow-circle-down {
    position: absolute;
    right: 30px;
    color: var(--secondaryTextColor);
    font-size: 20px;
}

.parcelas .Mui-expanded .semestre-header .fa-arrow-circle-down {
    transform: rotate(180deg);
}

.parcelas .MuiExpansionPanel-root:first-of-type {
    margin-top: 30px;
}

.parcelas .MuiExpansionPanel-root:last-of-type {
    margin-bottom: 32px;
}

.parcelas .MuiExpansionPanelDetails-root {
    padding: 0px;
    display: block;
}
.parcelas .parcelas-header {
    font-size: 13px;
    font-family: 'Gotham Bold', Arial, sans-serif;
    color: var(--primaryTextColor);
    padding: 15px 0;
    line-height: 26px;
    border-bottom: 1px solid #eee;
}

.parcelas .parcelas-header .fa-info-circle {
    font-size: 20px;
    margin-left: 5px;
    transform: translateY(2px);
}

.parcelas .parcelas-item {
    font-family: 'Gotham Book', Arial, sans-serif;
    font-size: 12px;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    color: var(--primaryTextColor);
}

.parcelas .parcelas-item strong {
    font-family: 'Gotham Bold', Arial, sans-serif;
}

.parcelas .parcelas-durante .parcelas-item:nth-of-type(odd),
.parcelas .parcelas-depois .parcelas-item:nth-of-type(odd) {
    background: #fbfbfb;
}

.modal-comprovante-renda {
    background-color: var(--primaryBackground);
    color: var(--primaryTextColor) !important;
    position: absolute;
    z-index: 9;
    padding: 25px 10px 0 25px;
    right: 15px;
    left: 15px;
}

.modal-comprovante-renda p {
    color: var(--primaryTextColor);
    font-size: 16px;
    line-height: 26px;
    font-family: 'Gotham Book', Arial, sans-serif;
}

.modal-comprovante-renda ul li {
    list-style: none;
    margin-top: 15px;
}

.modal-comprovante-renda ul {
    padding-left: 0;
}

.modal-comprovante-renda ul li strong {
    font-family: 'Gotham Bold', Arial, sans-serif;
}

.modal-comprovante-renda .close-button,
.modal-parcelas .close-button {
    position: absolute;
    top: 0px;
    right: 0px;
    color: var(--primaryTextColor);
    cursor: pointer;
    width: 48px;
    height: 48px;
}

.modal-parcelas {
    background-color: var(--primaryBackground);
    color: var(--primaryTextColor);
    position: absolute;
    z-index: 5;
    padding: 40px;
}

.modal-parcelas p {
    color: var(--primaryTextColor);
    font-size: 14px;
    text-align: left;
    font-family: 'Gotham Book', Arial, sans-serif;
    line-height: 19px;
    margin-top: 20px;
}

.modal-parcelas h1 {
    font-size: 18px;
    text-align: center;
    font-family: 'Gotham Book', Arial, sans-serif;
}

.parcelas .MuiExpansionPanel-root:not(.Mui-expanded) {
    overflow: hidden;
}

.modal-resultado-simulacao-root .percentual-variavel {
    display: inline;
    padding: 0px 7px
}

.modal-resultado-simulacao-root .percentual-variavel input {
    border: none;
    border-bottom: 2px dashed var(--primaryTextColor);
    font-family: 'Gotham Black';
    text-align: center;
    color: var(--primaryTextColor);
    padding-bottom: 0px;
    font-size: 18px;
    margin-left: 4px;
    background: none;
    width: 41px;
}

.modal-resultado-simulacao-root .percentual-variavel span {
    display: inline-block;
    margin-right: 5px;
    font-family: 'Gotham Black';
}

.modal-resultado-simulacao-root .percentual-select {
    border: none;
    border-bottom: 2px dashed rgba(74,74,74,0.7);
    font-family: 'Gotham Black';
    text-align: center;
    color: var(--primaryTextColor);
    font-size: 17px;
    background: none;
    margin-right: 6px;
    margin-left: 6px;
}

.modal-resultado-simulacao-root .onda-1 {
    margin-top: -33%;
}

.simulacao-completa .semestres {
    float: none;
    margin-top: 5px;
    display: inline-block;
    color: #4A4A4A;
    padding: 0 5px;
}

@media only screen and (min-width: 600px){

    .modal-resultado-simulacao-root.MuiContainer-root {
        padding: 2vh 20vw 0 20vw;
    }
    .modal-resultado-simulacao-root .logo-ies img{
        height: auto;	
        width: 225px;
    }
    .modal-resultado-simulacao-root .resumo-simulacao-text{
        width: 70%;
        margin: 0 auto;
        font-size: 16px;
        line-height: 20px;
    }
    .simulacao-resumo {
        padding-bottom: 190px;
    }
    .simulacao-resumo button.btn {
        margin-top: 30px;
    }

    .modal-resultado-simulacao-root .onda-3 {
        margin-top: -110px;
        max-width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .btn-recalcular{
        width: 256px !important;
        float: right;
    }
    .simulacao-completa .simulacao-completa-parcelas{
        padding-bottom: 30px;
    }
    .modal-resultado-simulacao .MuiPaper-root{
        border-radius: 20px;
    }
    .simulacao-completa .semestres{
        width: 80px;
    }
    .modal-parcelas {
        left: 0;
        top: 10px;
        right: 110px;
    }
    .modal-parcelas:before {
        content: '';
        position: absolute;
        right: 0;
        top: 48%;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-left-color: var(--primaryTextColor);
        border-right: 0;
        margin-top: -20px;
        margin-right: -20px;
    }
    .simulacao-completa .calculadora button{
        width: 160px;
        float: right;
        margin-top: -6px;
    }
}
@media only screen and (min-width: 960px){
    .modal-resultado-simulacao-root .onda-3 {
        margin-top: -184px;
    }
    .modal-resultado-simulacao-root .resumo-simulacao-text{
        width: 570px;
    }
}

@media only screen and (min-width: 1400px){
    .modal-resultado-simulacao-root .onda-3 {
        height: 25vw;
    }
}

@media print{

    html, body {
        width: 210mm;
        height: 297mm;
    }

    .MuiCollapse-container {
        visibility: visible !important;
        height: auto !important;
    }
    .onda-3, .onda-1,.btn.MuiButton-root, .close-button, .semestres a, header, footer, .percentual-variavel a, .modal-simulacao-footer{
        display: none !important;
    }
    .simulacao-resumo{
        padding-top: 50px !important;
        padding-bottom:50px;
    }

    .simulacao-completa{
        padding-top: 0 !important;
        z-index: 1;
        margin-bottom: 20px;
        background-color: transparent;
    }
    .simulacao-completa .semestres {
        width: 30px;
    }
    .simulacao-completa .calculadora input{
        border-bottom: 0px;
    }

    .simulacao-completa .simulacao-completa-parcelas, 
    .simulacao-completa .parcelas, 
    .simulacao-completa .valores{
		padding-bottom: 0;
    }

    .parcelas{        
        page-break-before: always !important;
    }

    .parcelas .MuiExpansionPanel-root{
		page-break-inside: avoid !important;
    }
    
    .parcelas .semestre-header .fa-arrow-circle-down{
        display: none;
    }
    .parcelas .parcelas-item{
        padding: 16px;
    }
    .percentual-variavel input{
        border-bottom: none !important;
    }
}
