@font-face {
    font-family: 'Gotham Bold';
    src: url('../fonts/gotham/GothamBold.otf') format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Medium';
    src: url('../fonts/gotham/GothamMedium.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Black';
    src: url('../fonts/gotham/GothamBlack.otf') format("opentype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Ultra';
    src: url('../fonts/gotham/GothamUltra.otf') format("opentype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Book';
    src: url('../fonts/gotham/GothamBook.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gotham Book Italic';
    src: url('../fonts/gotham/GothamBook-Italic.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}