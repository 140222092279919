.tit-ies{
	padding: 0 30px;
	margin-top: 1rem;
	line-height: 1;
}

.logo-ies{
	text-align: center;
}

.logo-ies img{
	width: 40%;
	margin: 2rem auto 0;
}

.tit-simulacao{
	text-align: center;
	color: #67778F;
	font-size: 3vw;
	font-family: "Gotham Bold", Arial, sans-serif;
	margin: 1rem 0 0 0;
}

.bloco-simulacao {
	position: relative;
	z-index: 2;
}

.bloco-form-simulacao{
	display: flex;
}

.bloco-form-simulacao .MuiCard-root{
	z-index: 2;
	border-radius: 0 0 4px 4px;	
}

.bloco-form-simulacao .MuiCard-root .btn{
	width: 100%;
}

.bloco-form-simulacao a,
.bloco-form-simulacao span.porque-pedimos{
	font-size: 3.5vw;
	font-family: 'Gotham Bold', Arial, sans-serif;
	text-decoration: none;
	color: var(--primaryTextColor);
	display: block;
	margin: 4vw auto 10vw auto;
	width: 80%;
}

.checkbox-news span{
	color: #99A8BF;
	font-size: 3vw;
}

.invalid-checkbox .checkbox-news span {
	color: #f44336;
}

p.subtit-ies{
	color: var(--primaryTextColor);
}
.container-simulacao{
	position: relative;
	box-shadow: 0 10px 20px 0 rgba(0,0,0,0.11);
}
.tabs-simulacao .MuiTab-root{
	width: 100%;
	text-align: center;
	font-family: 'Gotham Bold', Arial, sans-serif;
	font-size: 3vw;
	color: var(--primaryTextColor);
	text-transform: capitalize;
	line-height: 1.4;
	opacity: 0.3;
	padding-bottom: 10px;
	z-index: 10;
	background-color: #fff;
	display: flex !important;
}
.tabs-simulacao{
	margin-top: 3vw;
}

.tabs-simulacao .MuiTab-root.active{
	opacity: 1;
}

.tabs-simulacao .tab-simulacao:first-of-type, 
.tabs-simulacao .tab-simulacao:first-of-type .MuiTab-root{
	border-radius: 20px 0 0 0;
}
.tabs-simulacao .tab-simulacao:last-of-type,
.tabs-simulacao .tab-simulacao:last-of-type .MuiTab-root{
	border-radius: 0 20px 0 0;
}

.tab-simulacao {
	min-width: 25%;
	box-sizing: border-box;
	width: 50%;
}
.tabs-simulacao .MuiTabs-indicator{
	display: none;
}
.tabs-simulacao .MuiTabs-scrollButtons{
	position: absolute;
	z-index: 100;
	top: 4px;
	transform: translateY(50%);
}
.tabs-simulacao .MuiTabs-scrollButtons:first-of-type{
	left: -10px;
}
.tabs-simulacao .MuiTabs-scrollButtons:last-of-type{
	right: -10px;
}

.bloco-form-simulacao a,
.bloco-form-simulacao span.porque-pedimos{
	margin: 1vw 0px 28px 30px;
	width: unset;
}
@media only screen and (min-width: 600px){
	
	h2.tit-ies {
		font-size: 36px;
	}
	.tit-simulacao,
	.checkbox-news span,
	.bloco-form-simulacao a,
	.bloco-form-simulacao span.porque-pedimos,
	.tabs-simulacao .MuiTab-root{
		font-size: 13px;
	}
	.bloco-form-simulacao .MuiCard-root{
		width: 100%;
	}
	.tabs-simulacao .MuiTab-root{
		min-width: auto;
	}
	.tabs-simulacao .MuiTabs-scrollButtons{
		display: none;
	}
	.tab-simulacao {
		min-width: 20%;
	}
}
@media only screen and (min-width: 960px){	

	h2.tit-ies{
		padding: 0;
		margin-top: 60px;
		line-height: 69px;
		text-align: left;
		font-size: 62px;
		letter-spacing: -2.07px;
	}
	.logo-ies{
		text-align: left;
	}
	.logo-ies img {
		width: 215px;
		height: auto;
		margin-top: 85px;
		margin-bottom:35px;
	}
	.tit-simulacao{
		margin-top: 60px;
	}
	.tit-simulacao,
	.checkbox-news span,
	.bloco-form-simulacao a,
	.bloco-form-simulacao span.porque-pedimos{
		font-size: 14px;
	}
	.bloco-form-simulacao{
		margin-top: -15px;
	}
	.tabs-simulacao{
		margin-top: 20px;
	}
	.tabs-simulacao .MuiTab-root{
		height: 65px;
		font-size: 14px;
		padding-bottom: 20px;
	}
	
	.bloco-form-simulacao .MuiCard-root .MuiCardContent-root{
		padding: 15px 30px;
	}
}

.Mui-error .MuiInputBase-input{
	color: #f44336;
}