.MuiTextField-root .MuiInputBase-input,
.MuiTextField-root .MuiInputLabel-formControl,
.MuiTextField-root .MuiTypography-root {
    color: #455266;
    font-family: 'Gotham Bold', Arial, sans-serif;
    font-size: 14px;
    letter-spacing: -0.3px;
}

.Mui-focused input{
    color: #0064E6 !important;
}

.bloco-form-simulacao .MuiTextField-root .MuiInputLabel-shrink {
    color: #99A8BF !important;
    font-family: 'Gotham Bold', Arial, sans-serif;
    font-size: 12px;
    margin-top: 6px;
}

.MuiTextField-root .MuiFormHelperText-root {
    color: #0064E6;
    font-family: 'Gotham Medium', Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
}

.MuiInputBase-root.MuiInput-underline:before{
    border-bottom: 1px solid #DCDEE0;
}

.MuiInputBase-root.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 2px solid #0064E6;
}

.MuiCard-root.card-form-simulacao{
	overflow: visible;
}

.p-relative{
	position: relative;
}

.lgpd-policies{
    width: 100%;
    text-align: center;
    margin-bottom: 28px;
}

.lgpd-policies a.lgpd-policies-link{
    display: unset;
    width: unset;
    margin: 0;
    text-decoration: underline;
}