#etapas_solicitacao{
	background-color: var(--secondaryTextColor);
	padding-bottom: 50px;
}

#etapas_solicitacao h2{
	margin-top: 2rem;
}

#etapas_solicitacao .item-solicitacao{
	text-align: center;
}

#etapas_solicitacao .item-solicitacao img{
	margin-top: 0;
}

#etapas_solicitacao .item-solicitacao span{
	font-family: 'Gotham Black';
	text-align: center;
	color: var(--btnDefaultTextColor);
	font-size: 12px;
	display: block;
	padding-top: 6px;
}

#etapas_solicitacao .plus-ind{
	text-align: center;
}

#etapas_solicitacao .plus-ind img{
	width: 70%;
    padding-top: 20%;
    padding-left: 10%;
    padding-right: 10%;
}

@media only screen and (min-width: 960px){

	#etapas_solicitacao .item-solicitacao img{
		width: 50%;
	}

	#etapas_solicitacao .item-solicitacao span{
		font-size: 19px;
	}

	#etapas_solicitacao .plus-ind img{
		width: 70%;
		padding-top: 20%;
		padding-left: 10%;
		padding-right: 10%;
	}
}