footer{
	padding-top: 20vw;
}
html[data-theme="credneo"] footer{
	padding-top: 10vw;
}
footer img{
	margin-bottom:45px
}
footer nav{
	margin-bottom: 45px;
}
footer nav .MuiLink-root{
	font-family: 'Gotham Bold', Arial, sans-serif;
	color: var(--primaryTextColor);
	padding: 5px 10px;
	display: block;
}
footer .txt-address{
	font-family: 'Gotham Medium', Arial, sans-serif;
	color: #455266;
	font-size: 16px;
}
footer .small-txt{
	color: #67778F;
	font-size: 13px;
	text-align: center;
}
footer .small-txt.textao{
	text-align: justify;
}
footer .txt-tel{
	color: var(--secondaryTextColor);
	font-family: "Gotham Bold", Arial, sans-serif;
	font-size: 24px;
}
footer .txt-label-tel{
	color: #67778F;
	margin-bottom: 0;
	font-size: 13px;
	font-family: "Gotham Medium", Arial, sans-serif;
}

@media only screen and (min-width: 960px){
	footer{
		padding-top: 15vw;
	}
	footer .txt-address{
		font-size: 12px;
		line-height: 16px;
	}
	footer .small-txt{
		font-size: 10px;
		line-height: 14px;
		margin: 2vw 0;
		padding: 0 10px;
	}
	footer .txt-label-tel{
		margin-top: 1vw;
		font-size: 10px;
		line-height: 12px;
	}
	footer .txt-tel{
		font-size: 20px;
		line-height: 28px;
	}

	footer nav .MuiLink-root{
		display: inline;
	}
}
