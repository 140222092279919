.onda-1{
	width: 100%;
	margin-top: -55%;
	position: absolute;
	background-image: var(--urlOndaMob1);
	height: 70vw;
	background-size: cover;
	z-index: 1;
}

.onda-3{
	display: none;
}

@media only screen and (min-width: 600px){
	.onda-1{
		display: none;
	}

	.onda-3{
		display: block;
		width: 100%;
		margin-top: -19%;
		position: absolute;
		background-image: var(--urlOndaDesk);
		height: 35vw;
		background-size: cover;
		z-index: 1;
	}
}
@media only screen and (min-width: 960px){
	.onda-3{
		margin-top: -20%;
	}
}