.btn.btn-menu {
  background-color: unset;
  height: 50px;
  padding-right: 24px;
  border: 0;
}
.btn-menu .MuiButton-label {
  display: block;
}

.btn-menu .barra-grande {
  width: 100%;
  height: 4px;
  background-color: var(--primaryTextColor);
  border-radius: 5px;
  margin-bottom: 4px;
}
.btn-menu .barra-pequena {
  width: 50%;
  height: 4px;
  background-color: var(--primaryTextColor);
  border-radius: 5px;
}

header .MuiGrid-container {
  flex-wrap: nowrap;
}

header .btn.btn-entrar {
  padding: 2px 30px;
  margin-top: 10px;
}

header .header-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
}

@media only screen and (min-width: 960px) {
  h1 img.fluid {
    margin-top: 26px;
    margin-right: 30px;
    width: 167px;
  }
  .menu-desk {
    margin-top: 30px;
  }
  .menu-desk a {
    color: var(--primaryTextColor);
    font-size: 14px;
    font-family: 'Gotham Bold', Arial, sans-serif;
    padding: 10px 20px;
    text-decoration: none;
  }
  .menu-desk a:hover {
    text-decoration: none;
  }
  header .btn.btn-entrar {
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .social-media.wpp {
    display: none;
  }
}

#fb-root > div.fb_dialog {
  right: initial !important;
  left: 18pt;
  z-index: 9999999 !important;
  bottom: 68pt !important;
}
.fb-customerchat.fb_invisible_flow.fb_iframe_widget iframe {
  right: initial !important;
  left: 18pt !important;
  bottom: 68pt !important;
}

.social-media.messenger {
  position: fixed;
  top: auto;
  bottom: 68pt;
  width: 45pt;
  height: 45pt;
  right: initial !important;
  left: 18pt;
  border-radius: 50%;
  box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  z-index: 999;
}

@media only screen and (max-width: 768px) {
  .social-media #wpp {
    display: block !important;
  }

  .social-media.wpp {
    position: fixed;
    top: auto;
    bottom: 68pt;
    width: 45pt;
    height: 45pt;
    right: initial !important;
    left: 18pt;
    border-radius: 50%;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    z-index: 999;
  }

  .social-media.messenger {
    position: fixed;
    top: auto;
    bottom: 18pt;
    width: 45pt;
    height: 45pt;
    right: initial !important;
    left: 18pt;
    border-radius: 50%;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    -moz-transition: transform 0.5s;
    z-index: 999;
  }

  #fb-root > div.fb_dialog {
    right: initial !important;
    left: 18pt;
    z-index: 99999 !important;
    bottom: 18pt !important;
  }
  .fb-customerchat.fb_invisible_flow.fb_iframe_widget iframe {
    right: initial !important;
    left: 18pt !important;
    bottom: 18pt !important;
  }
}

@media print {
  .social-media.wpp {
    z-index: -1;
  }

  .social-media.messenger {
    z-index: -1;
  }

  #fb-root > div.fb_dialog {
    z-index: -1 !important;
  }
}
